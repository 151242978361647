export const gateway = "https://jeminsurance-api.link.wawanesalife.com";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"jeminsurance.link.wawanesalife.com": {
		a: {
			broker: "jeminsurance",
			accessibilityLink: "null",
			themeSwitcher: "null",
			privacyLink: "https://jeminsurance.ca/privacy-policy/",
			scope: "jeminsurance#branding#jeminsurance.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@jeminsurance.ca",
			video_link: "null",
			map2: "12-963 Henderson Hwy,Winnipeg,MB,R2K 2M3",
			environment_id: "stable",
			map1: "987 Portage Avenue,Winnipeg,MB,R3G 0R7",
			brands: "jeminsurance.link.wawanesalife.com",
			map4: "640 Central Avenue,Ste Rose du Lac,MB,R0L 1S0",
			map3: "9-35 Lakewood Boulevard,Winnipeg,MB,R2J 2M8",
			introduction: "null",
			phone: "204-774-4471",
			menuPhone: "button",
			environment: "stable",
			website: "https://jeminsurance.ca",
			ftcLink: "null",
			layout: "flat",
			g4: "null",
			photo3: "hide",
			photo4: "hide",
			formLabels: "show",
			photo1: "hide",
			photo2: "hide",
			tagline: "We’re here to help",
			approve_user: "link_jeminsurance",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "jeminsurance.link.wawanesalife.com",
		},
		b: {
			broker: "jeminsurance",
			accessibilityLink: "null",
			themeSwitcher: "null",
			privacyLink: "https://jeminsurance.ca/privacy-policy/",
			scope: "jeminsurance#branding#jeminsurance.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@jeminsurance.ca",
			video_link: "null",
			map2: "12-963 Henderson Hwy,Winnipeg,MB,R2K 2M3",
			environment_id: "stable",
			map1: "987 Portage Avenue,Winnipeg,MB,R3G 0R7",
			brands: "jeminsurance.link.wawanesalife.com",
			map4: "640 Central Avenue,Ste Rose du Lac,MB,R0L 1S0",
			map3: "9-35 Lakewood Boulevard,Winnipeg,MB,R2J 2M8",
			introduction: "null",
			phone: "204-774-4471",
			menuPhone: "button",
			environment: "stable",
			website: "https://jeminsurance.ca",
			ftcLink: "null",
			layout: "flat",
			g4: "null",
			photo3: "hide",
			photo4: "hide",
			formLabels: "show",
			photo1: "hide",
			photo2: "hide",
			tagline: "We’re here to help",
			approve_user: "link_jeminsurance",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "jeminsurance.link.wawanesalife.com",
		},
	},
};

//updated on Fri Jun 07 2024 19:30:37 GMT-0700 (Pacific Daylight Time)
